import * as React from 'react';
import { AppProps, SeoObject } from 'src/app/types';
import { AppPageLayout, AppPageLinks } from 'src/app.consumer/components';
import { Localized } from 'src/app/components';

const seo: SeoObject = {
  title: 'Help page',
};

// TODO: i18n
export const HelpPage = (props: AppProps) => {
  return (
    <AppPageLayout
      sidebar={
        <>
          <div className='pb-6 md:pb-8 md:-mt-10'>
            <Localized className='text-h1 font-bold md:hidden' dictKey={'staticLocales:pages./help.link label'} />
            <Localized className='text-h2 font-bold hidden text-accent-500 md:block' dictKey={'staticLocales:pages./help.subtitle'} />
          </div>
          <AppPageLinks />
        </>
      }
      // subTitle={<Localized dictKey={'staticLocales:pages./help.subtitle'} />}
      // title={<Localized dictKey={'staticLocales:pages./help.link label'} />}
      {...{ ...props, seo }}
    >
      {/* <Grid container spacing={2}>
        <Grid item xs={12}>
          <LocalizedLink href={'/help/customer'}>
            <Localized dictKey={'staticLocales:pages./help.pages./customer.link label'} />
          </LocalizedLink>
        </Grid>
        <Grid item xs={12}>
          <LocalizedLink href={'/help/supplier'}>
            <Localized dictKey={'staticLocales:pages./help.pages./supplier.link label'} />
          </LocalizedLink>
        </Grid>
      </Grid> */}

    </AppPageLayout>
  );
};

export default HelpPage;
